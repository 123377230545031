import React, { useState, useEffect } from 'react';
import '../styles/App.sass';
import '../styles/uplift.scss';
import {
    Footer,
    Header,
    Funciones,
    Patrocinantes
} from '../components';
import { Link } from 'react-router-dom';

const Home = () => {
    const MONEDAS = ['USD'];
    const [moneda, setMoneda] = useState(MONEDAS[0]);
    let [resposive, setResposive] = useState(window.screen.width);
    useEffect(() => {
        console.log('t', window.screen.width)
        setResposive(window.screen.width);
    }, [window.screen.width]);
    return (
        <>
            <Header
                urlBanner={`${process.env.REACT_APP_URL_IMAGES}banner-image-1280x571.png`}
                urlBannerMovil={`${process.env.REACT_APP_URL_IMAGES}banner-mobile-390x517.png`}
            />
            <div className="container mb-5">
                <div className="row">
                    <div className="col col-12 col-lg-6 mb-4">
                        <div className="ld--title">Join the RB Community</div>
                        <div className="ld--card">
                            <span className="tags mb-4">SUBSCRIPTION</span>
                            <div className="format_text">
                                <p>Join our fan community and stay updated with all the latest news! If you're looking for more, subscribe to unlock exclusive content, special deals, and offers—it's our way of rewarding our most dedicated fans</p>
                                <a href='/pricing' target={`_blank`} className='ld--btn-shop'>
                                    Join Fanbase
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col col-12 col-lg-6 mb-4">
                        <div className="ld--title">Fresh Tracks</div>
                        <div className="ld--card">
                            <div className='listener'>
                                <iframe
                                    src="https://open.spotify.com/embed/playlist/2304y8rtEcrjUF6YgrydZx?utm_source=generator&theme=0"
                                    width="100%"
                                    height="160px"
                                    style={{ borderRadius: '12px' }}
                                    allowFullScreen=""
                                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
                <Funciones
                    moneda={moneda}
                    setMoneda={setMoneda}
                    monedas={MONEDAS}
                />
            </div>
            <div className="bg_cont mb-5" id="metaverse" style={{ backgroundImage: `url(${process.env.REACT_APP_URL_IMAGES}immersive-event-bg-image.png)` }}>
                <div className="container">
                    <div className="row">
                        <div className="col col-12 col-lg-7">
                            <div className="ld--title mb-5">On june - Join our first immersive Event!!</div>
                            <div className="mb-3 stags">
                                <span className='tags'>IMMERSIVE EVENT</span>
                                <div>JUNE 22 2025 08:00 PM</div>
                            </div>
                            <p>We are reimagining concert experiences with our first immersive event.  With Apple vision Pro you can enjoy from your home the ultimate concert experience.</p>
                            <a className="ld--btn" target="_blank" rel="noreferrer" href="https://play.ticketmundo.com/programs/live-pii6-6pvwjy"><span>Get Access</span></a>
                        </div>
                        <div className="col col-12 col-lg-5 _p">
                            <div className='oculus_cont'>
                                <img src={`${process.env.REACT_APP_URL_IMAGES}tm-vision-pro.png`} alt="OCULUS" />
                                <p>Apple vision required</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mb-5" id="streaming">
                <div className="ld--title">Streaming</div>
                <div className="ld--card">
                    <div className="ld--item-shop row">
                        <div className="col col-12 col-lg-6 mb-6 streaming_iplift">
                            <h3 className='h3_iplift mb-3'>Europe Tour - UK Performance</h3>
                            <div className="mb-3 stags">
                                <span className='tags'>LIVE STREAMING</span>
                                <div>APR 22 2025 04:00 PM</div>
                            </div>
                            <p>We're back online! It’s time to perform our new album live for our online community. Don’t miss this special livestream event, made just for you—our online fans!</p>
                            <Link to='https://play.ticketmundo.com/programs/live-pii6-6pvwjy' target='_blank' className='ld--btn-shop'>
                                Get access
                            </Link>
                        </div>
                        <div className="col col-12 col-lg-6 mb-6">
                            <iframe width="100%" height="280"
                                src="https://www.youtube.com/embed/s9P4Oshkv6I"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen>
                            </iframe>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mb-5" >
                <div className="row">
                    <div className="col col-12 col-lg-6 mb-4 " id="store">
                        <div className="ld--title" >Store</div>
                        <div className="ld--card centered_card mb-4">
                            <div className="ld--item-shop no_reverse row">
                                <div className="col col-12 col-lg-6">
                                    <img src={resposive > 1000 ? `https://ticketmundo.sfo2.digitaloceanspaces.com/demo-eu/merch.png` : `${process.env.REACT_APP_CDN_URL_IMAGES}responsive_merch.png`} alt="merch" style={{ maxWidth: '100%', margin: `0 auto` }}/>
                                </div>
                                <div className="col col-12 col-lg-6">
                                    <h3 className='h3_iplift mb-3'>Discover The Rock Band Store</h3>
                                    <span className='tags mb-3'>STORE</span>
                                    <p >Yes. Our <b>Rock 'til You Die</b> collection is already available for purchase!</p>
                                    <Link to='https://rockbandstore.ticketmundo.com/' target='_blank' className='ld--btn-shop'>
                                        Go to store
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="ld--title" >Rock for a Cause</div>
                        <div className="ld--card centered_card">
                            <div className="ld--item-shop row">
                                <div className="col col-12 col-lg-6">
                                    <h3 className='h3_iplift mb-3'>Rock Band Fundation</h3>
                                    <div className="mb-4">
                                        <span className='tags'>DONATION</span>
                                    </div>
                                    <p><b>It’s Not Just About Rock n' Roll!</b> We care about our planet and our communities, and we invite you to join us in making a difference.</p>
                                    <Link to='https://demo-rockband.ticketmundo.com/donations/13' target='_blank' className='ld--btn-shop'>
                                        Donate
                                    </Link>
                                </div>
                                <div className="col col-12 col-lg-6">
                                    <img src={ resposive > 1000 ? 'https://cdn.ticketmundo.live/demo-eu/RockBandFundation.png' : `${process.env.REACT_APP_CDN_URL_IMAGES}responsive_RockBandFundation.png` } alt="merch" style={{ maxWidth: '100%', width: '100%' }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col col-12 col-lg-6 mb-4" id="NFT">
                        <div className="ld--title">Digital Treasures</div>
                        <div className="ld--card ld--wrap-funciones no_warp_r">
                            <span className='tags mb-3'>DIGITAL CONTENT</span>
                            <p>Great news! You can now purchase our digital collectibles and unlock exclusive rewards. Explore our curated selection below and find the one that’s perfect for you!</p>
                            <div className="ld--funciones nft_iplift">
                                <div className="ld--item-funcion ld--card ld--smresponsive">
                                    <div className="ld--fecha">
                                        <img src={'https://i.scdn.co/image/ab67706c0000da8444bbc0bfbd39984aa7f1eee7'} alt="OCULUS" />
                                    </div>
                                    <div className="ld--lugar">
                                        <span className="ld--ciudad">
                                            Rock master Songs
                                        </span>
                                        <span className="ld--recinto">
                                            Album digital songs
                                        </span>
                                    </div>
                                    <div className="time_iplift">
                                        <span className="ld--ciudad">
                                            1.29 ETH
                                        </span>
                                        <span className="ld--recinto">
                                           min
                                        </span>
                                    </div>
                                </div>
                                <div className="ld--item-funcion ld--card ld--smresponsive">
                                    <div className="ld--fecha">
                                        <img src={'https://i.scdn.co/image/ab67706c0000da8444bbc0bfbd39984aa7f1eee7'} alt="rockband" />
                                    </div>
                                    <div className="ld--lugar">
                                        <span className="ld--ciudad">
                                            Concerts & Band Photos
                                        </span>
                                        <span className="ld--recinto">
                                            digital files
                                        </span>
                                    </div>
                                    <div className="time_iplift">
                                        <span className="ld--ciudad">
                                            0.88 ETH
                                        </span>
                                        <span className="ld--recinto">
                                           min
                                        </span>
                                    </div>
                                </div>
                                <div className="ld--item-funcion ld--card ld--smresponsive">
                                    <div className="ld--fecha">
                                        <img src={'https://i.scdn.co/image/ab67706c0000da8444bbc0bfbd39984aa7f1eee7'} alt="OCULUS" />
                                    </div>
                                    <div className="ld--lugar">
                                        <span className="ld--ciudad">
                                            Streaming Shows
                                        </span>
                                        <span className="ld--recinto">
                                            digital movie
                                        </span>
                                    </div>
                                    <div className="time_iplift">
                                        <span className="ld--ciudad">
                                            0.99 ETH
                                        </span>
                                        <span className="ld--recinto">
                                           min
                                        </span>
                                    </div>
                                </div>
                                <div className="ld--item-funcion ld--card ld--smresponsive">
                                    <div className="ld--fecha">
                                        <img src={'https://cdn.ticketmundo.live/demo-eu/rock_avatar.png'} alt="avatar" />
                                    </div>
                                    <div className="ld--lugar">
                                        <span className="ld--ciudad">
                                            Avatar Rock Band
                                        </span>
                                        <span className="ld--recinto">
                                            digital pics
                                        </span>
                                    </div>
                                    <div className="time_iplift">
                                        <span className="ld--ciudad">
                                            0.29 ETH
                                        </span>
                                        <span className="ld--recinto">
                                           min
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Patrocinantes />
            <Footer />
        </>
    );
};

export default Home;
